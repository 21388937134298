import { Api } from "@/models/class/api.class";
import { ResponseListMasterType } from "@/models/interface-v2/master.interface";
import { ErrorResponseModel, RequestQueryParamsModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class MasterTypeServices extends HttpClient {
  constructor() {
    super()
  }

  /**
   * get list master type
   * @param params query params
   * @returns list of master type
   */
  listMaster(params: RequestQueryParamsModel): Promise<ResponseListMasterType[]> {
    return this.get<ResponseListMasterType[]>(Api.MasterType, { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error)
    })
  }
}

export const masterTypeService = new MasterTypeServices()
