


























import { ResponseListMasterType } from "@interface/master.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { IOption } from "@interface/common.interface";
import Vue from "vue";
import { masterTypeService } from "@service/master-type.service";

export default Vue.extend({
  name: "CSelectSalesType",
  props: {
    value: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dtOpt: [] as IOption[],
      loading: false,
    };
  },
  mounted() {
    this.getListSalesType();
  },
  methods: {
    getListMasterType(params: RequestQueryParamsModel): Promise<ResponseListMasterType[]> {
      return masterTypeService.listMaster(params);
    },
    async getListSalesType(): Promise<void> {
      try {
        this.loading = true;
        const res = await this.getListMasterType({name: "RFQ_SALES_TYPE"});
        this.dtOpt = res.map(x => { return { key: x.value, value: x.value };});
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading = false;
      }
    },
    onSelect(e: string): void {
      this.$emit("input", e);
      this.$emit("on-select", e);
    },
    onChange(e: string): void {
      this.$emit("input", e);
      this.$emit("change", e);
    },
  },
});

